/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px
xs={} sm={}  md={} lg={} xl={}
* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from "react"
// gatsby route
import { navigate } from "gatsby"
// emailjs sdk
import emailjs from "emailjs-com"
// react-hook-form
import { useForm, Controller } from "react-hook-form"
// matrial-ui methods
import { makeStyles } from "@material-ui/core/styles"
// matrial-ui components
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
// atoms components
import Button from "@atoms/Button"
import TextFeild from "@atoms/TextFeild"
import SelectField from "@atoms/SelectField"
import RadioField from "@atoms/RadioField"
// callback compnent
import FormSuccess from "@molecules/Form/FormSuccess"

/**
 *
 */
const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 118,
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
    },
  },
}))

/**
 *
 * @param {*} props
 * @returns
 */
export default function SearchForm(props) {
  const classes = useStyles()

  // dailog state
  const [isOpen, setIsOpen] = React.useState(false)
  /**
   * @close_dailog
   */
  const closeDialog = () => {
    setIsOpen(false)
    navigate(`/`)
  }

  /**
   *
   * @handle_form
   */
  const {
    handleSubmit,
    control,
    formState: { isSubmitted, isValid, errors },
    reset,
  } = useForm()

  /**
   * @handel_controll_form
   */
  const onSubmit = data => {
    // send email via emailjs
    emailjs
      .send(
        "service_9x4o6ii",
        "template_3hxhwz8",
        data,
        "user_LAV2TzWxySvbqtoDwSO4G"
      )
      .then(
        result => {
          setIsOpen(true)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <Box className={classes.form}>
      {/**  */}
      <FormSuccess handleClose={closeDialog} isOpen={isOpen} />

      {/* form title */}
      <Box>
        <Typography
          variant="h4"
          style={{
            fontWeight: "bold",
            fontSize: 25,
            margin: " 50px 0",
          }}
          gutterBottom
        >
          ابحث عن السيارة
        </Typography>
      </Box>

      {/* form  */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {/**  */}
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextFeild
                  label="الاسم"
                  id="name-feild"
                  placeholder="اكتب الاسم"
                  error={errors.name}
                  isSubmitting={isValid && isSubmitted}
                  field={field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {/**  */}
            <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextFeild
                  id="telephone-number-feild"
                  label="رقم الهاتف"
                  placeholder="+00 000 00 000"
                  error={errors.phone}
                  isSubmitting={isValid && isSubmitted}
                  field={field}
                />
              )}
            />
          </Grid>

          {/**********
         *    <Controller
                  name="test"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=''
                  render={({ field }) => ( 
                      <SelectField   
                        label="نموذج"  
                        field={field} 
                        withIcon
                        options={[
                          {
                            value: "hgkfh",
                            label: "نعم",
                            icon: "https://res.cloudinary.com/menofiaevent-com/image/upload/v1622961854/avatars/models/tesla_q2xygc.png"
                          },
                          {
                            value: "hjfjh",
                            label: "لا",
                            icon: "https://res.cloudinary.com/menofiaevent-com/image/upload/v1622961931/avatars/models/kin_auujlc.png"
                          }
                      ]}
                       />
                  )}
                />* **/}

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/**  */}
            <Controller
              name="model"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextFeild
                  id="modal-name-feild"
                  label="ماركة السيارة"
                  placeholder="+00 000 00 000"
                  error={errors.model}
                  isSubmitting={isValid && isSubmitted}
                  field={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/**  */}
            <Controller
              name="model"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextFeild
                  id="modal-name-feild"
                  label="نوع السيارة"
                  placeholder="+00 000 00 000"
                  error={errors.model}
                  isSubmitting={isValid && isSubmitted}
                  field={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/**  */}
            <Controller
              name="factory"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextFeild
                  id="factory-name-feild"
                  label="سنة التصنيع"
                  placeholder="+00 000 00 000"
                  error={errors.factory}
                  isSubmitting={isValid && isSubmitted}
                  field={field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            {/**  */}
            <Controller
              name="status"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <RadioField
                  label="الحالة"
                  error={errors.status}
                  isSubmitting={isValid && isSubmitted}
                  options={[
                    {
                      value: "new",
                      label: "جديد",
                    },
                    {
                      value: "old",
                      label: "مستعل",
                    },
                  ]}
                  field={field}
                />
              )}
            />
          </Grid>

          {/*   */}
          <Grid item xs={12} sm={12}>
            {/**  */}
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextFeild
                  id="notes-feild"
                  label="ملاحظات"
                  placeholder="+00 000 00 000"
                  error={errors.notes}
                  isSubmitting={isValid && isSubmitted}
                  field={field}
                />
              )}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          style={{ margin: "20px 0" }}
          disabled={!errors && isSubmitted}
        >
          {!errors && isSubmitted ? "جارى ارسال النموذج" : " دوّر لي سيارة"}
        </Button>
      </form>
    </Box>
  )
}
